<template>
  <div class="user-result">
    <div id="canvas" v-if="resultInfo!=='' && resultInfo.bh_wh_arr !== undefind">
      <div class="user-title">您的姓名打分解析</div>
        <div class="user-info">
          <div class="content">
            <h1 id="username">姓名: {{form.username}}</h1>
          </div>
          <div style="width:100%;">
          <h1 id="">笔画:
            <template v-if="resultInfo.bh_wh_arr.bihua1 != 0 ">{{ resultInfo.bh_wh_arr.bihua1 }}</template>
            <template v-if="resultInfo.bh_wh_arr.bihua2 != 0 ">、{{ resultInfo.bh_wh_arr.bihua2 }}</template>
            <template v-if="resultInfo.bh_wh_arr.bihua3 != 0 ">、{{ resultInfo.bh_wh_arr.bihua3 }}</template>
            <template v-if="resultInfo.bh_wh_arr.bihua4 != 0 ">、{{ resultInfo.bh_wh_arr.bihua4 }}</template>
          </h1>
          </div>
          <div class="count">
            {{resultInfo.xmdf > 0 ? resultInfo.xmdf.toFixed(2) : 0}}分
          </div>
      </div>
      <div class="content">
        <div class="title">姓名三才配置（重点关注）</div>
        <div class="item">
          <div>
            <h1 class="tit">人地才三才配置:</h1>
            <p v-html="resultInfo.rssancai.title"></p>
          </div>
          <div>
            <h1 class="tit">姓名解析:</h1>
            <p v-html="resultInfo.rssancai.content"></p>
          </div>
          <div>
            <h1  class="tit">基础运:</h1>
            <p v-html="resultInfo.rssancai.jcy"></p>
          </div>
          <div>
            <h1  class="tit">成功运:</h1>
            <p v-html="resultInfo.rssancai.cgy"></p>
          </div>
          <div>
            <h1  class="tit">社交运:</h1>
            <p v-html="resultInfo.rssancai.rjgx"></p>
          </div>
          <div>
            <h1  class="tit">性格:</h1>
            <p v-html="resultInfo.rssancai.xg"></p>
          </div>
        </div>
        <div class="title">天格信息</div>
        <div class="item">
          <div>
            <h1  class="tit">天格数:</h1>
            <p>{{resultInfo.tiangearr.tiangee}}</p>
          </div>
          <div>
            <h1  class="tit">吉凶:</h1>
            <p>{{resultInfo.tiangearr.jx}}</p>
          </div>
          <div>
            <h1  class="tit">数理:</h1>
            <p>{{resultInfo.tiangearr.yy}}</p>
          </div>
          <div>
            <h1  class="tit">签语:</h1>
            <p>{{resultInfo.tiangearr.as}}</p>
          </div>
          <div>
            <h1  class="tit">含义:</h1>
            <p v-html="resultInfo.tiangearr.content"></p>
          </div>
        </div>
        <div class="title">人格信息</div>
        <div class="item">
          <div>
            <h1  class="tit">人格数:</h1>
            <p>{{resultInfo.rengearr.rengee}}</p>
          </div>
          <div>
            <h1  class="tit">吉凶:</h1>
            <p>{{resultInfo.rengearr.jx}}</p>
          </div>
          <div>
            <h1  class="tit">数理:</h1>
            <p>{{resultInfo.rengearr.yy}}</p>
          </div>
          <div>
            <h1  class="tit">签语:</h1>
            <p>{{resultInfo.rengearr.as}}</p>
          </div>
          <div>
            <h1  class="tit">含义:</h1>
            <p v-html="resultInfo.rengearr.content"></p>
          </div>
        </div>
        <div class="title">地格信息</div>
        <div class="item">
          <div>
            <h1 class="tit">地格数:</h1>
            <p v-html="resultInfo.digearr.digee"></p>
          </div>
          <div>
            <h1  class="tit">吉凶:</h1>
            <p v-html="resultInfo.digearr.jx"></p>
          </div>
          <div>
            <h1  class="tit">数理:</h1>
            <p v-html="resultInfo.digearr.yy"></p>
          </div>
          <div>
            <h1  class="tit">签语:</h1>
            <p v-html="resultInfo.digearr.as"></p>
          </div>
          <div>
            <h1  class="tit">含义:</h1>
            <p v-html="resultInfo.digearr.content"></p>
          </div>
        </div>
        <div class="title">外格信息</div>
        <div class="item">
          <div>
            <h1  class="tit">外格数:</h1>
            <p v-html="resultInfo.waigearr.waigee"></p>
          </div>
          <div>
            <h1  class="tit">吉凶:</h1>
            <p v-html="resultInfo.waigearr.jx"></p>
          </div>
          <div>
            <h1  class="tit">数理:</h1>
            <p v-html="resultInfo.waigearr.yy"></p>
          </div>
          <div>
            <h1  class="tit">签语:</h1>
            <p v-html="resultInfo.waigearr.as"></p>
          </div>
          <div>
            <h1  class="tit">含义:</h1>
            <p v-html="resultInfo.waigearr.content"></p>
          </div>
        </div>
        <div class="title">总格信息</div>
        <div class="item">
          <div>
            <h1  class="tit">总格数:</h1>
            <p v-html="resultInfo.zonggearr.tiangee"></p>
          </div>
          <div>
            <h1  class="tit">吉凶:</h1>
            <p v-html="resultInfo.zonggearr.jx"></p>
          </div>
          <div>
            <h1  class="tit">数理:</h1>
            <p v-html="resultInfo.zonggearr.yy"></p>
          </div>
          <div>
            <h1  class="tit">签语:</h1>
            <p v-html="resultInfo.zonggearr.as"></p>
          </div>
          <div>
            <h1  class="tit">含义:</h1>
            <p v-html="resultInfo.zonggearr.content"></p>
          </div>
        </div>
      </div>
	  </div>
    <div class="loading-items" id="loading-items" v-if="resultInfo === ''">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
        <div class="weui-loading"></div>
        <div class="weui-loadmore__tips">测算结果正在加载中 大约5-7秒 请不要退出~</div>
        </div>
      </div>
    </div>
    <div class="loading-items" id="loading-items" v-if="isConver">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
        	<div class="weui-loading"></div>
        	<div class="weui-loadmore__tips">结果图片生成中，请不要退出~</div>
      	</div>
	    </div>
	  </div>
    <div class="button-items" v-if="resultInfo !== ''  && resultInfo.bh_wh_arr !== undefind">
      <div class="notice">
        为保护您的隐私 系统不记录测算结果
      </div>
      <input type="button" :disabled="isConver ? true : false" value="点击生成图片" @click="saveImg" />
    </div>
    <div class="dialog-tip" v-if="showDialog">
      <div class="content">
        <p>为了保护您的隐私，系统不记录您的测算结果，浏览后请点击下方按钮生成图片，长按保存到相册，以免退出后丢失。</p>
        <div class="btn">
          <input type="button" value="我知道了" @click="gotEvent" />
        </div>
      </div>
    </div>
    <div class="dialog-tip center" v-if="showError">
      <div class="content">
        <p>系统异常，麻烦您联系客服为您退款。</p>
        <div class="btn">
          <input type="button" value="联系客服" @click="goHomeEvent" />
        </div>
      </div>
	  </div>
  </div>
</template>
<style src="./style.css"></style>
<script>
import html2canvas from 'html2canvas'
import { getXMCS } from '../api/index'
import wx from 'weixin-js-sdk'
  export default{
    data() {
      return {
        resultInfo: '',
        imagePhoto: '',
        height: 0,
        form: {},
        isConver: false,
        showDialog: false,
        showError: false
      }
    },
    mounted() {
      const params = {
        xing: this.$route.query.xing,
        username:this.$route.query.username,
      }
      this.form = params
      if(params.xing) {
        this.initData(params)
      }
    },
	methods: {
	  async initData(params) {
      const res = await getXMCS(params)
      if(res.data) {
        this.resultInfo =  res.data
        if (this.resultInfo.rssancai.content) {
          this.resultInfo.rssancai.content = this.resultInfo.rssancai.content.replace(/\n/g,'<br>')
        }
        if (this.resultInfo.tiangearr.content) {
          this.resultInfo.tiangearr.content = this.resultInfo.tiangearr.content.replace(/\n/g,'<br>')
        }
        if (this.resultInfo.rengearr.content) {
          this.resultInfo.rengearr.content = this.resultInfo.rengearr.content.replace(/\n/g,'<br>')
        }
        if (this.resultInfo.digearr.content) {
          this.resultInfo.digearr.content = this.resultInfo.digearr.content.replace(/\n/g,'<br>')
        }
        if (this.resultInfo.waigearr.content) {
          this.resultInfo.waigearr.content = this.resultInfo.waigearr.content.replace(/\n/g,'<br>')
        }
        if (this.resultInfo.zonggearr.content) {
          this.resultInfo.zonggearr.content = this.resultInfo.zonggearr.content.replace(/\n/g,'<br>')
        }
        setTimeout(() => {
          this.showDialog = true
        },500)
        return false;
      }
      if (res.code === 99999) {
        this.resultInfo = {};
		    this.showError = true
      }
	  },
    goHomeEvent() {
		this.showError = false
		var miniprogram;
		  const isWechatMiniProgram = /miniProgram/i.test(navigator.userAgent.toLowerCase());
		  const isWechatBrowser = /MicroMessenger/i.test(navigator.userAgent);
		  if (isWechatMiniProgram) {
			  miniprogram = "miniprogram1"
		  } else if (isWechatBrowser) {
			  miniprogram = "wechatBrowser"
		  } else {
			 miniprogram =  "normal"
		  }	
      try {  
			if (miniprogram === 'miniprogram1') {
				wx.miniProgram.reLaunch({url: `/pages/index/index`})
			}
		} catch (e) {
				//console.log(wx, '不在微信开发者环境');
		}
	  },
	  taskScreenshort(dom) {
      //const dom = document.getElementById("canvas")
      const width = dom.offsetWidth
      this.height = dom.offsetHeight
      let scale = 2 // 设定倍数这样不会模糊
      return new Promise((resolve,reject) => {
        html2canvas(dom, {
          allowTaint: true, //允许被污染
          width: width,
          heigth: this.height,
          windowHeight: this.height,
          windowWidth: width,
          scale: scale,
          scrollX: 0,
          scrollY: 0,
          y: 0,
          x: -10,
          useCORS: true
        }).then(canvas => {
          resolve(canvas)
        }).catch(err => {
          reject(err)
        })
      })
    },
	  saveImg() {
      //alert(document.getElementById("canvas"))
      this.isConver = true
      this.taskScreenshort(document.getElementById("canvas")).then(canvas => {
        this.imagePhoto = canvas.toDataURL('image/png', 1.0) //将图片转为base64
        localStorage.setItem('base64', this.imagePhoto)
        //console.log(this.imagePhoto)
        this.isConver = false
        window.location.href= `#/showImg?height=${this.height}`;
        this.showDialog = false
      })
	  },
    gotEvent() {
      this.showDialog = false
    }
  }
}
</script>